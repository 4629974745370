<template>
	<div class="page_box">

		<div class="bom_box">
			<div class="bom_btn_flex">
				<div class="lefts">
					{{ page_title }}
				</div>
			</div>
			<div class="bom_box_list">
				<div class="title-title">{{bumen_info.title}} {{title_month}}考勤 ({{date_start}}- {{date_end}})</div>
			
				<el-table :data="list" stripe style="width: 100%" header-cell-class-name="el_table_title"
					@selection-change="handle_list_change" v-loading="loading" height="570">
					<el-table-column prop="" label="部门信息">
						<el-table-column prop="title" label="部门" width="150"></el-table-column>
					</el-table-column>
					<el-table-column prop="" label="出勤情况">
						<el-table-column prop="id" label="出勤人数" width=""></el-table-column>
						<el-table-column prop="workDay" label="出勤天数" width=""></el-table-column>
						<el-table-column prop="yearHoliday" label="年休假" width=""></el-table-column>
						<el-table-column prop="holiday" label="节日" width=""></el-table-column>
						<el-table-column prop="sickLeave" label="病假" width=""></el-table-column>
						<el-table-column prop="absenceLeave" label="事假" width=""></el-table-column>
						<el-table-column prop="workLeave" label="工伤" width=""></el-table-column>
					</el-table-column>
					<el-table-column prop="" label="带班下井补助">
						<el-table-column prop="daiaban0" label="零点带班" width=""></el-table-column>
						<el-table-column prop="daiaban4" label="四点带班" width=""></el-table-column>
					</el-table-column>
					<el-table-column prop="" label="下井补助">
						<el-table-column prop="xiajing" label="下井" width=""></el-table-column>
					</el-table-column>
					<el-table-column prop="" label="其他补助">
						<el-table-column prop="climbTime" label="登山次数" width=""></el-table-column>
						<el-table-column prop="shangquTime" label="山区补助" width=""></el-table-column>
					</el-table-column>
				</el-table>
				<div class="pages">
					<page :pageson='count' @row="row" @currentPage="currentPage"></page>
				</div>
			</div>
		</div>


	</div>
</template>
<script>
	import dayjs from 'dayjs'
	
	import page from "@/components/page/page.vue"; //分页  统一的



	export default {
		components: {
			page,



		},
		data() {
			return {
				bumen_id: '',
				month: '',
				bumen_info: {},
				title_month: '',
				date_start: '',
				date_end: '',
				//

				loading: false, //加载总
				page_title: '', //页面名称  来之路由
				check_list: [], //选中的
				//提交数据
				forms: {
					keyword: '',
					identity: '',
				},
				list: [{
						name: '123123'
					},
					{
						name: '123123'
					}, {
						name: '123123'
					}, {
						name: '123123'
					},
				],
				pages: {
					page: 1,
					limit: 10,
				},
				count: 0,
				times: [], //时间范围




				//
				user_range_list: [{
						title: '范围1',
						value: '1'
					},
					{
						title: '范围2',
						value: '2'
					},
					{
						title: '范围3',
						value: '3'
					},
				],
				user_range_select: '',


				month_select: '',

				date_list: [
					// {date: '2024-05-01', week: '星期日',},
				],



				//
				type_list: [{
						title: '类别1',
						value: '1'
					},
					{
						title: '类别2',
						value: '2'
					},
				],
				type_select: ''
			};
		},
		computed: {},
		watch: {
			times(value) {
				console.log('value', value)
			}
		},

		created() {
			this.page_title = this.$route.meta.title
			this.month = this.$route.query.month || '';
			this.bumen_id = this.$route.query.id || '';
			
			this.set_date_info()
			this.query_bumen_info()
			this.get_list()
		},
		mounted() {

		},
		methods: {
			set_date_info() {
				this.title_month = dayjs(this.month).format('YYYY年MM月')
				
				let day_info = dayjs(this.month);
				this.date_start = day_info.startOf('month').format("YYYY-MM-DD")
				//时间的结束
				this.date_end = day_info.endOf('month').format("YYYY-MM-DD")
				
			},
			query_bumen_info() {

				this.$api("getCategory", {
					id: this.bumen_id
				}, "get").then((res) => {
					this.loading = false
					if (res.code == 200) {
						this.bumen_info = res.data;
					}
				});

			},
			//接受条数
			currentPage(page) {
				console.log(page)
				this.pages.page = page
				this.get_list()
			},
			// 接收条数
			row(row) {
				console.log(row)
				this.pages.limit = row
				this.get_list()
			},
			//选择
			handle_list_change(e) {
				console.log('多选', e)
				this.check_list = e
			},
			//搜素
			handle_search() {
				this.pages.page = 1
				this.get_list()
			},
			//表单回调 触发重新渲染数据
			handle_get_list() {
				this.get_list()
			},
			//统一的列表接口
			get_list() {
				console.log(this.forms)
				this.loading = true
				this.$api("kaoqin/getDepartList", {
					// mobile:this.mobile
					...this.forms,
					...this.pages,
					deptId: this.bumen_id,
					month: this.month,
				}, "get").then((res) => {
					this.loading = false
					if (res.code == 200) {
						this.count = res.data.count
						this.list = res.data.list
					} else {
						alertErr(res.msg)
					}
				});
			},


			do_kaoqin_manager() {
				this.$router.push('/kaoqin_user_manager')
			},
			do_import_kaoqin() {},
			do_import_xiajin() {},
		},



	};
</script>

<style scoped lang="less">
	.bom_box_list {
		.title-title {
			font-weight: bold;
			font-size: 18px;
			color: #333;
			text-align: center;
			padding: 28px 0 33px 0;
		}
	}

	.page_box {
		height: 100%;
		background: #FFFFFF;

		// background-color: red;
		.top_box {
			width: 100%;
			// height: 80px;
			border-radius: 8px 8px 8px 8px;
			padding-bottom: 29px;

			.top_title {
				padding: 10px 17px;
				font-family: Microsoft YaHei, Microsoft YaHei;
				font-weight: bold;
				font-size: 14px;
				color: #333333;
			}

			.top_flex {
				display: flex;
				align-items: center;
				// justify-content: space-between;
				flex-wrap: wrap;
				padding: 0 77px;

				.search_flex {
					// width: 25%;
					display: flex;
					align-items: center;
					padding-right: 48px;

					.texts {
						font-family: Microsoft YaHei, Microsoft YaHei;
						font-weight: 400;
						font-size: 14px;
						color: #333333;
						white-space: nowrap;
						padding-right: 12px;
					}

					.inputs_box {
						flex: 1;

						input {
							width: 236px;
							height: 40px;
							background: #FFFFFF;
							border-radius: 0px 0px 0px 0px;
							border: 1px solid #E8E8E8;
							padding: 0 9px;
							font-family: Microsoft YaHei, Microsoft YaHei;
							font-weight: 400;
							font-size: 14px;
							color: #747474;

						}
					}


					.btn_ends {
						width: 80px;
						height: 40px;
						background: linear-gradient(90deg, #157DE9 0%, #3697FD 100%);
						border-radius: 2px 2px 2px 2px;
						text-align: center;
						line-height: 40px;
						font-family: Microsoft YaHei, Microsoft YaHei;
						font-weight: 400;
						font-size: 14px;
						color: #FFFFFF;
						cursor: pointer;

						&:hover {
							background: linear-gradient(90deg, #0564ca 0%, #3697FD 100%);

						}
					}

				}
			}




		}

		.bom_box {
			background: #FFFFFF;

			.bom_btn_flex {
				display: flex;
				align-items: center;
				justify-content: space-between;
				width: 100%;
				height: 51px;
				background: #FFFFFF;
				border-radius: 4px 4px 4px 4px;
				margin-top: 20px;
				padding: 0 20px;
				border-bottom: 1px solid #EDF0F6;

				.lefts {
					font-family: Microsoft YaHei, Microsoft YaHei;
					font-weight: bold;
					font-size: 14px;
					color: #333333;
				}

				.rights {
					display: flex;
					align-items: center;

					.btns {
						margin-left: 20px;
						background: linear-gradient(90deg, #157DE9 0%, #3697FD 100%);
						padding: 6px 13px;
						font-family: Microsoft YaHei, Microsoft YaHei;
						font-weight: 400;
						font-size: 14px;
						color: #FFFFFF;
						cursor: pointer;

						&:hover {
							background: linear-gradient(90deg, #0564ca 0%, #3697FD 100%);

						}
					}

				}
			}

			.bom_box_list {
				width: 1640px;
				height: 670px;
				padding: 20px 20px 32px 20px;

				.pages {
					padding-top: 30px;
				}
			}

		}
	}
</style>